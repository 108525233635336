import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';

import ArrowLink from '../../ArrowLink/ArrowLink';
import SalesButton from '../../SalesButton/SalesButton';

const MobileAppSource: React.FC = ({ children }) => (
  <>
    <p className="lead">
      <FormattedMessage id="payment_processing.payment_sources.mobile_app.title"/>
    </p>

    <p style={{whiteSpace: 'pre-wrap'}}>
      <FormattedMessage id="payment_processing.payment_sources.mobile_app.body"
        values={{
          ...defaultFormatValues,
          link: (
            <ArrowLink to="https://docs.kushki.com/" color="primary" external={true}><FormattedMessage id="payment_processing.payment_sources.mobile_app.link"/></ArrowLink>
          ),
          video: (
            <span className="embed-responsive embed-responsive-16by9 mb-5">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/pAiwDUhcfU4?color=white&controls=0&loop=1&playlist=pAiwDUhcfU4&modestbranding=1&rel=0&autoplay=1"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              />
            </span>
          ),
        }}
      />
    </p>

    <div className="d-flex align-items-center flex-column flex-md-row justify-content-between mt-7">
      <div className="content-cta">
        <FormattedMessage id="payment_processing.payment_sources.mobile_app.cta"/>
      </div>
      <SalesButton className="mt-2 mt-lg-0 ml-0 ml-lg-2 d-block d-lg-inline-block" big={true} positionTag="mid"><FormattedMessage id="buttons.sales"/></SalesButton>
    </div>
  </>
);

export default MobileAppSource;
