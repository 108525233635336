import React from 'react';
import { FormattedMessage } from 'react-intl';

import ArrowLink from '../../ArrowLink/ArrowLink';
import Countries from '../../Countries/Countries';
import cardImg from './assets/images/cards.svg';

const CardMethod: React.FC = ({ children }) => (
  <div className="row mt-4">
    <div className="col-sm-12 text-left">
      <p className="lead">
        <FormattedMessage id="payment_processing.payment_methods.card.title"/>
      </p>

      <p>
        <FormattedMessage id="payment_processing.payment_methods.card.body"/>
      </p>

      <ArrowLink to="/payments/card" color="primary"><FormattedMessage id="payment_processing.payment_methods.card.link"/></ArrowLink>

      <p className="mt-4"><b><FormattedMessage id="payment_processing.payment_methods.available_in"/></b></p>
      <Countries countries={['CL', 'CO', 'MX', 'EC', 'PE']} />
    </div>
  </div>
);

export default CardMethod;
