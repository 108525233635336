import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import bulletImg from './assets/images/bullet.svg';
import CardMethod from './CardMethod';
import CashMethod from './CashMethod';
import './styles.scss';
import TransferMethod from './TransferMethod';

import cardIcon from './assets/images/cardIcon.svg';
import cashIcon from './assets/images/cashIcon.svg';
import transferIcon from './assets/images/transferIcon.svg';

const ProductsPaymentMethods: React.FC = ({ children }) => (
  <section className="products__payment-methods bg-light-gray-1-5 pt-layout-5">
    <div className="container">
      <div className="row">
        <div className="col-lg-4">
          <h2 className="mb-5">
            <FormattedMessage id="payment_processing.payment_methods.title"/>
          </h2>
          <p className="lead mb-4" style={{whiteSpace: 'pre-wrap'}}>
            <FormattedMessage id="payment_processing.payment_methods.subtitle"
              values={defaultFormatValues}
            />
          </p>
        </div>
        <div className="col-lg-7 offset-lg-1 mt-layout-4 mt-lg-0">
          <Tabs>
            <TabList>
              <Tab><img src={cardIcon} height="14px" width="auto" className="mr-0 mr-lg-2"/> <br className="d-block d-sm-none"/><FormattedMessage id="payment_processing.payment_methods.card.tab_title"/></Tab>
              <Tab><img src={cashIcon} height="14px" width="auto" className="mr-0 mr-lg-2"/> <br className="d-block d-sm-none"/><FormattedMessage id="payment_processing.payment_methods.cash.tab_title"/></Tab>
              <Tab><img src={transferIcon} height="14px" width="auto" className="mr-0 mr-lg-2"/> <br className="d-block d-sm-none"/><FormattedMessage id="payment_processing.payment_methods.transfer.tab_title"/></Tab>
            </TabList>

            <TabPanel>
              <CardMethod />
            </TabPanel>
            <TabPanel>
              <CashMethod />
            </TabPanel>
            <TabPanel>
              <TransferMethod />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>

    <div className="payments-methods-parallax mt-layout-6">
      <div className="payments-methods-parallax__content"/>
    </div>
  </section>
);

export default ProductsPaymentMethods;
