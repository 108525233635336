import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';

import CTAButton from '../../CTAButton/CTAButton';
import SalesButton from '../../SalesButton/SalesButton';

import Lottie from 'react-lottie';
import animationData from './assets/json/product-hero-animation.json';

class ProductsHero extends React.Component {
  public render() {
    return (
      <section className="hero overflow-visible">
        <div className="container text-center">
          <div className="row">
            <div className="col-12 order-2 order-lg-1">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <h1 className="text-primary d-none d-md-block">
                    <FormattedMessage id="payment_processing.hero.title"/>
                  </h1>
                  <h3 className="text-primary d-block d-md-none">
                    <FormattedMessage id="payment_processing.hero.title"/>
                  </h3>
                  <p className="lead mt-6" style={{whiteSpace: 'pre-wrap'}}>
                    <FormattedMessage id="payment_processing.hero.subtitle"
                      values={defaultFormatValues}
                    />
                  </p>

                  <div className="button-responsive-row mt-7">
                    <SalesButton big={true} positionTag="top"><FormattedMessage id="buttons.sales"/></SalesButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 order-1 order-lg-2">
              <div className="mb-layout-5 mt-layout-5">
                <Lottie
                  options={{
                    animationData,
                    autoplay: true,
                    loop: true,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice'
                    }
                  }}
                />
              </div>
            </div>
          </div>

        </div>
      </section>
    );
  }
}

export default ProductsHero;
