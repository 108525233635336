import React, { ReactNode } from 'react';
import caImg from './assets/images/CA.png';
import clImg from './assets/images/CL.png';
import coImg from './assets/images/CO.png';
import ecImg from './assets/images/EC.png';
import mxImg from './assets/images/MX.png';
import peImg from './assets/images/PE.png';
import usImg from './assets/images/US.png';
import './styles.scss';

interface CountriesProps {
  countries: ('CL' | 'EC' | 'CO' | 'US' | 'MX' | 'CA' | 'PE')[];
}

const getImage = (code: string) => {
  switch (code) {
    case 'CL':
      return clImg;
      break;
    case 'CO':
      return coImg;
      break;
    case 'US':
      return usImg;
      break;
    case 'EC':
      return ecImg;
      break;
    case 'CA':
      return caImg;
      break;
    case 'PE':
      return peImg;
      break;
    case 'MX':
      return mxImg;
      break;
  }
};

const Countries: React.FC<CountriesProps> = (props: CountriesProps) => (
  <div className="country-row">
    {props.countries.map((country) =>
      <img className="country" src={getImage(country)} key={country} alt={country} />
    )}
  </div>
);

export default Countries;
