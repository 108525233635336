import React from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss'
import awtoLogoImg from './assets/images/awto-logo.svg';
import awtoImg from './assets/images/awtobg.jpg';
import claroLogoImg from './assets/images/claro.svg';
import claroImg from './assets/images/claro_bg.jpg';
import rappiLogoImg from './assets/images/rappi.svg';
import rappiImg from './assets/images/rappibg.jpg';
import snrLogoImg from './assets/images/snr.svg';
import snrImg from './assets/images/snr_bg.jpg';
import './styles.scss';

interface CustomerCarouselState {
  activeSlide: number;
}

const params = {
  breakpoints: {
    0: {
      spaceBetween: 30,
    },
    768: {
      spaceBetween: 100,
    },
    1200: {
      spaceBetween: 200,
    }
  },
  centeredSlides: true,
  coverflowEffect: {
    depth: 100,
    modifier: 1,
    rotate: 0,
    slideShadows: false,
    stretch: 0,
  },
  effect: 'coverflow',
  slideToClickedSlide: true,
  slidesPerView: 'auto',
}

class CustomerCarousel extends React.Component<{}, CustomerCarouselState> {

  constructor(props: any) {
    super(props);

    // this.toggleAccountDropdown = this.toggleAccountDropdown.bind(this);

    this.state = {
      activeSlide: 0
    };
  }

  // public toggleAccountDropdown() {
  //   this.setState({
  //     accountDropdownShow: !this.state.accountDropdownShow
  //   });
  // }

  public render() {
    return (
      <Swiper {...params}>
        <div className="customer-card">
          <img className="customer-img" src={rappiImg}/>
          <div className="customer-card-content">
            <img src={rappiLogoImg} className="customer-logo" />

            <p>
              Apoyamos al continuo crecimiento de Rappi, al abrirle las puertas a un mercado que estaba desatendido en Colombia, debido a la poca bancarización en la región.
            </p>
            <p>
              Esto se logró al implementar los servicios de  pago mediante transferencia y efectivo, los cuales se sumaron a la modalidad de tarjetas de crédito que Rappi ya poseía.
            </p>
          </div>
        </div>
        <div className="customer-card">
          <img className="customer-img" src={snrImg} />
          <div className="customer-card-content">
            <img src={snrLogoImg} className="customer-logo" />

            <p>El Supernotariado de Colombia deseaba mejorar su servicio para los ciudadanos colombianos, pero siendo una entidad gubernamental atareada, no contaba ni con el tiempo, ni los recursos necesarios para llevar a cabo un proyecto de digitalización.
            </p>

            <p>
              Por lo que les ofrecimos una plataforma online, diseñada e implementada de principio a fin, que emite alrededor de 3500 Certificados de Tradición y Libertad diariamente. Estos son descargados de forma rápida y segura, desde la comodidad del hogar, reduciendo el tiempo del proceso a 1 minuto y liberando considerablemente los puntos físicos del SNR.
            </p>
          </div>
        </div>
        <div className="customer-card">
          <img className="customer-img" src={awtoImg} />
          <div className="customer-card-content">
            <img src={awtoLogoImg} className="customer-logo" />

            <p>Awto es la primera aplicación de “auto compartido” por minuto en Chile. Querían crear una experiencia fluida y armoniosa para sus clientes en el friccionado sistema de pago disponible en Chile.
            </p>

            <p>
              Ellos incorporaron nuestro servicio de transacción completa, que  permite pagar desde la página sin abandonar la aplicación.
            </p>

            <p>
              Esto aumentó sus niveles de conversión al no necesitar la autenticación bancaria y redirección, brindando un nivel de experiencia hasta ahora desconocido en el mercado chileno.
            </p>
          </div>
        </div>
        <div className="customer-card">
          <img className="customer-img" src={claroImg} />
          <div className="customer-card-content">
            <img src={claroLogoImg} className="customer-logo" />

            <p>Claro deseaba ofrecer servicios que apoyaran a las 300 mil pymes que existen en el mercado colombiano y que no poseen la infraestructura necesaria para tener una tienda online o realizar cobros.
            </p>

            <p>
             Por lo que implementó nuestros “link de pagos” para ofrecer un servicio completo y personalizable, que permite a sus clientes realizar cobros online, crear un catálogo de productos, asignarles precios y fotografías; y determinar el stock de cada uno. Ayudando a impulsar la economía del país.
            </p>
          </div>
        </div>
      </Swiper>
    );
  }
}

export default CustomerCarousel;
