import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import AccordionSection from '../../Accordion/AccordionSection';
import inPersonIconImg from './assets/images/in-person-icon.svg';
import mobileIconImg from './assets/images/mobile-icon.svg';
import socialNetworkIconImg from './assets/images/social-network-icon.svg';
import webIconImg from './assets/images/web-icon.svg';
import InPersonSource from './InPersonSource';
import MobileAppSource from './MobileAppSource';
import SocialMediaSource from './SocialMediaSource';
import './styles.scss';
import WebsiteSource from './WebsiteSource';


const ProductsPaymentSources: React.FC = ({ children }) => (
  <section className="bg-white section-xl">
    <div className="container">
      <div className="row">
        <div className="offset-lg-2 col-lg-8 text-center">
          <h2 className="mb-5">
            <FormattedMessage id="payment_processing.payment_sources.title"/>
          </h2>
          <p className="lead mb-7">
            <FormattedMessage id="payment_processing.payment_sources.subtitle"
              values={defaultFormatValues}
            />
          </p>
        </div>
      </div>

      <div className="accordion d-lg-none">
        <AccordionSection title={
          <><img src={webIconImg} /> <FormattedMessage id="payment_processing.payment_sources.website.tab_title"/></>
        }>
          <WebsiteSource />
        </AccordionSection>
        <AccordionSection title={
          <><img src={mobileIconImg} /> <FormattedMessage id="payment_processing.payment_sources.mobile_app.tab_title"/></>
        }>
          <MobileAppSource />
        </AccordionSection>
        <AccordionSection title={
          <><img src={socialNetworkIconImg} /> <FormattedMessage id="payment_processing.payment_sources.social_media.tab_title"/></>
        }>
          <SocialMediaSource />
        </AccordionSection>
        <AccordionSection title={
          <><img src={inPersonIconImg} /> <FormattedMessage id="payment_processing.payment_sources.in_person.tab_title"/></>
        }>
          <InPersonSource />
        </AccordionSection>
      </div>


      <Tabs className="row payment-source-container d-none d-lg-flex" selectedTabClassName="selector__tab--selected">
        <TabList className="col-lg-3 payment-source-container__selector" >
          <Tab className="selector__tab"><img src={webIconImg} /> <FormattedMessage id="payment_processing.payment_sources.website.tab_title"/></Tab>
          <Tab className="selector__tab"><img src={mobileIconImg} /> <FormattedMessage id="payment_processing.payment_sources.mobile_app.tab_title"/></Tab>
          <Tab className="selector__tab"><img src={socialNetworkIconImg} /> <FormattedMessage id="payment_processing.payment_sources.social_media.tab_title"/></Tab>
          <Tab className="selector__tab"><img src={inPersonIconImg} /> <FormattedMessage id="payment_processing.payment_sources.in_person.tab_title"/></Tab>
        </TabList>

        <div className="col-lg-9 payment-source-container__content">
          <TabPanel>
            <WebsiteSource />
          </TabPanel>
          <TabPanel>
            <MobileAppSource />
          </TabPanel>
          <TabPanel>
            <SocialMediaSource />
          </TabPanel>
          <TabPanel>
            <InPersonSource />
          </TabPanel>
        </div>
      </Tabs>
    </div>
  </section>
);

export default ProductsPaymentSources;
