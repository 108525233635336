import React from 'react';
import { FormattedMessage } from 'react-intl';
import Partners from '../../Partners/Partners';

import SalesButton from '../../SalesButton/SalesButton';
import CustomersFloatingScroll from '../../Customers/CustomersFloatingScroll/CustomersFloatingScroll';

const productsCTA: React.FC = ({ children }) => (
  <>
  <section className="my-layout-4 my-lg-layout-6">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <h2 className="mb-4">
            <FormattedMessage id="payment_processing.cta.title"/>
          </h2>
          <p className="lead">
            <FormattedMessage id="payment_processing.cta.subtitle"/>
          </p>
        </div>
        <div className="col-lg-6 text-left text-lg-right mb-7 mt-5 my-md-0">
          <div className="button-responsive-row">
            <SalesButton big={true} positionTag="bottom">
              <FormattedMessage id="buttons.sales"/>
            </SalesButton>
          </div>
        </div>
      </div>
      <div className="text-center mt-layout-3">
        <CustomersFloatingScroll />
      </div>
    </div>
  </section>
  <div className="pre-footer-bg"/>
  </>
);

export default productsCTA;
