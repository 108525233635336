import * as React from 'react';

import IndexLayout from '../layouts';

import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';

import CustomerCarousel from '../components/Products/CustomersCarousel/CustomersCarousel';
import ProductsAPI from '../components/Products/ProductsAPI/ProductsAPI';
import ProductsCTA from '../components/Products/ProductsCTA/ProductsCTA';
import ProductsHero from '../components/Products/ProductsHero/ProductsHero';
import ProductsPaymentMethods from '../components/Products/ProductsPaymentMethods/ProductsPaymentMethods';
import ProductsPaymentSources from '../components/Products/ProductsPaymentSources/ProductsPaymentSources';
import { LocaleTypes } from '../data/locales';

interface ProductsPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const ProductsPage: React.FC<ProductsPageProps> = ({ pathContext, location }) => {

  React.useEffect(() => {
    // Start of crazyegg code
    if( undefined !== window ){
      (function(w) {
        const s = document.createElement(`script`);
        s.src = `//script.crazyegg.com/pages/scripts/0110/8437.js`;
        s.async = true;
        const e = document.getElementsByTagName(`script`)[0];
        e.parentNode.insertBefore(s, e);
      })(window);
    }
  // End of crazyegg code
  })

  const content = () => (
    <>
      <SEO titleId="payment_processing.title" />
      <ProductsHero />

      <ProductsPaymentMethods />

      <ProductsPaymentSources />

      <ProductsAPI />

      <section className="customer-carousel-section py-layout-5">
        <div className="cut-bg"/>
        <CustomerCarousel />
      </section>

      <ProductsCTA />

      <Footer theme="primary" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default ProductsPage;
