import React from 'react';
import { FormattedMessage } from 'react-intl';

import ArrowLink from '../../ArrowLink/ArrowLink';
import Countries from '../../Countries/Countries';

const CashMethod: React.FC = ({ children }) => (
  <div className="row mt-4">
    <div className="col-sm-12 text-left">
    <p className="lead">
        <FormattedMessage id="payment_processing.payment_methods.cash.title"/>
      </p>

      <p>
        <FormattedMessage id="payment_processing.payment_methods.cash.body"/>
      </p>

      <ArrowLink to="/payments/cash" color="primary"><FormattedMessage id="payment_processing.payment_methods.cash.link"/></ArrowLink>

      <p className="mt-4"><b><FormattedMessage id="payment_processing.payment_methods.available_in"/></b></p>
      <Countries countries={['CL', 'CO', 'MX', 'EC', 'PE']} />
    </div>
  </div>
);

export default CashMethod;
