import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';

import SalesButton from '../../SalesButton/SalesButton';

const SocialMediaSource: React.FC = ({ children }) => (
  <>
    <p className="lead">
      <FormattedMessage id="payment_processing.payment_sources.social_media.title"/>
    </p>

    <p style={{whiteSpace: 'pre-wrap'}}>
      <FormattedMessage id="payment_processing.payment_sources.social_media.body"
        values={{
          ...defaultFormatValues,
          video: (
            <span className="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/tfs_0N0G004?color=white&controls=0&loop=1&playlist=tfs_0N0G004&modestbranding=1&rel=0&autoplay=1"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              />
            </span>
          ),
        }}
      />
    </p>

    <div className="d-flex align-items-center flex-column flex-md-row justify-content-between mt-7">
      <div className="content-cta">
        <FormattedMessage id="payment_processing.payment_sources.social_media.cta"/>
      </div>
      <SalesButton className="mt-2 mt-lg-0 ml-0 ml-lg-2 d-block d-lg-inline-block" big={true} positionTag="mid"><FormattedMessage id="buttons.sales"/></SalesButton>
    </div>
  </>
);

export default SocialMediaSource;
