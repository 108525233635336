import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatValues } from '../../../data/defaultFormat';

import ArrowLink from '../../ArrowLink/ArrowLink';
import apiImg from './assets/images/api.svg';
import bulletImg from './assets/images/bullet.svg';

const ProductsAPI: React.FC = ({ children }) => (
  <section className="py-layout-5 bg-dark-gradient">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-12 text-center">
          <h2 className="mb-7">
            <FormattedMessage id="payment_processing.api.title"/>
          </h2>

          <div className="row text-left mb-5">
            <div className="col-lg-12">
              <img src={apiImg} className="img-fluid mb-7" />
            </div>
            <div className="col-sm-6 offset-lg-1 col-lg-5">
              <p>
                <FormattedMessage id="payment_processing.api.body_1"
                  values={defaultFormatValues}
                />
              </p>
            </div>
            <div className="col-sm-6 col-lg-5">
              <p>
                <FormattedMessage id="payment_processing.api.body_2"
                  values={defaultFormatValues}
                />
              </p>
            </div>
          </div>

          <ArrowLink to="https://docs.kushki.com/" color="primary" external={true}>
           <FormattedMessage id="payment_processing.api.link"
              values={defaultFormatValues}
            />
          </ArrowLink>
        </div>
      </div>
    </div>
  </section>
);

export default ProductsAPI;
